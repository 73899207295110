import { rgba } from "polished";

export const white = rgba(255, 255, 255, 1);

export const black = rgba(42, 42, 42, 1);

export const red = rgba(186, 0, 40, 1);

export const darkRed = rgba(140, 1, 30, 1);

export const lightGrey = rgba(242, 242, 242, 1);

export const grey = rgba(230, 230, 230, 1);

export const darkGrey = rgba(110, 110, 110, 1);
