// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-service-tsx": () => import("./../src/pages/service.tsx" /* webpackChunkName: "component---src-pages-service-tsx" */),
  "component---src-pages-services-tsx": () => import("./../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-vehicle-tsx": () => import("./../src/pages/vehicle.tsx" /* webpackChunkName: "component---src-pages-vehicle-tsx" */),
  "component---src-pages-vehicles-tsx": () => import("./../src/pages/vehicles.tsx" /* webpackChunkName: "component---src-pages-vehicles-tsx" */)
}

