import React from "react";
import { Helmet } from "react-helmet";
import { GlobalStyle } from "./src/styles/global";
import "typeface-roboto";
import "typeface-roboto-condensed";

export const wrapPageElement = ({ element }) => (
  <>
    <Helmet>
      <title>Nigel Pugh Motors</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link
        type="text/css"
        href="https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css"
        rel="stylesheet"
      />
      <link
        type="text/css"
        href="mapbox-gl-controls/theme.css"
        rel="stylesheet"
      />
      <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
    </Helmet>
    <GlobalStyle />
    {element}
  </>
);
