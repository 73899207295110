import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import { black } from "./colors";

export const GlobalStyle = createGlobalStyle`
  ${reset}

  *, *:before, *:after {
    box-sizing: border-box;
  }

  html {
    box-sizing: border-box;
    height: 100%;
    min-height: 100%;
  }

  body {
    min-height: 100%;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    color: ${black};
  }

  a, a:visited, a:active  {
		color: ${black};
    text-decoration: none;
	}
`;
